import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import GatsbyLink from 'gatsby-link'
import { UserEventType } from '@flock/utils'

import slugify from 'slugify'
import { TrackingConfig, useTracking } from '../useTracking'
import { getNodeText } from '../../utils'

type TrackedButtonProps = ButtonProps & {
  trackingConfig?: TrackingConfig
  to?: string
}

const TrackedButton = (props: TrackedButtonProps) => {
  const { onClick, trackingConfig, to, ...otherProps } = props
  const { track } = useTracking()
  const onClickTracked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let trackingName = 'button-click'
    if (trackingConfig?.name) {
      trackingName = trackingConfig?.name
    } else if (props.children) {
      const nodeText = getNodeText(props.children)
      if (nodeText) {
        trackingName = slugify(nodeText.toLowerCase())
      }
    }
    track(trackingName, {
      type: 'button',
      ...trackingConfig?.properties,
      actionType: UserEventType.BUTTON_CLICK,
    })
    if (onClick) {
      onClick(event)
    }
  }

  if (to) {
    return (
      // @ts-ignore
      <Button
        component={GatsbyLink}
        {...otherProps}
        onClick={onClickTracked}
        sx={{
          ...otherProps.sx,
          textAlign: 'center',
        }}
        to={to}
      />
    )
  }

  return <Button {...otherProps} onClick={onClickTracked} />
}

TrackedButton.defaultProps = {
  trackingConfig: null,
  to: undefined,
}

export default TrackedButton
