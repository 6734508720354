import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

const LoadingCardPage = ({
  text,
  hideLoader,
}: {
  text: string
  hideLoader?: boolean
}) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    gap="16px"
    sx={{
      backgroundColor: 'green1.main',
    }}
  >
    <Typography variant="h4">{text}</Typography>
    {!hideLoader && <CircularProgress />}
  </Box>
)

export default LoadingCardPage

LoadingCardPage.defaultProps = {
  hideLoader: false,
}
