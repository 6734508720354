import querystring from 'querystring'

const BASE_URL = 'https://maps.googleapis.com/maps/api/'

type GenerateGoogleMapUrlParams = {
  type: string
  center?: string

  key?: string
  zoom?: number
  size?: string
  scale?: number
  format?: string
  maptype?: 'roadmap' | 'satellite' | 'hybrid' | 'terrain'
  language?: string
  region?: string
  markers?: string
  path?: string
  visible?: string
  style?: string
  location?: string
  pano?: string
  fov?: number
}

export const generateGoogleMapUrl = (opts: GenerateGoogleMapUrlParams) => {
  if (opts.type === 'staticmap') {
    if (opts.center === undefined) {
      throw new Error('center must be defined in options')
    } else if (opts.type === undefined) {
      throw new Error('type must be defined in options')
    }
  } else if (opts.type === 'streetview') {
    if (opts.location === undefined && opts.pano === undefined) {
      throw new Error('you must pass in location in options')
    }
  } else if (opts.type === 'streetview/metadata') {
    if (opts.location === undefined) {
      throw new Error('you must pass in location in options')
    }
  } else {
    throw new Error(
      `${opts.type} is an invalid type. Use "staticmap" or "streetview".`
    )
  }

  return `${BASE_URL + opts.type}?${querystring.stringify(opts)}`
}

// eslint-disable-next-line import/prefer-default-export
export const getNodeText: (node: React.ReactNode) => any = (node) => {
  if (['string', 'number'].includes(typeof node)) return node
  if (node instanceof Array) return node.map(getNodeText).join('')
  // @ts-ignore
  if (typeof node === 'object' && node) return getNodeText(node.props.children)
  return ''
}

export const abbreviationToState: { [key: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}
