import {
  FLOCK_PHONE_NUMBER,
  INVESTOR_EMAIL,
  PHONE_URL,
  TrackedButton,
} from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'

const MaintenancePage = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    gap="16px"
    sx={{
      backgroundColor: 'green1.main',
    }}
  >
    <Typography variant="h4">
      We&apos;re currently undergoing server maintenance and will be back soon!
    </Typography>
    <Typography variant="p2">
      If you have questions or an issue that needs immediate attention, please
      contact us.
    </Typography>

    <Box display="flex" gap="32px">
      <TrackedButton
        size="small"
        onClick={() => {
          window.open(PHONE_URL)
        }}
      >
        Call {FLOCK_PHONE_NUMBER}
      </TrackedButton>
      <TrackedButton
        size="small"
        onClick={() => window.open(`mailto:${INVESTOR_EMAIL}`)}
      >
        Email
      </TrackedButton>
    </Box>
  </Box>
)

export default MaintenancePage
